import React from 'react'

const Form = () => {
  return (
    <>
        <form>
            
        </form>
    </>
  )
}

export default Form